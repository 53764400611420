import { HttpClient } from '@angular/common/http';
import { Component, HostListener, NgZone } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PushNotifications, PushNotificationSchema, ActionPerformed } from '@capacitor/push-notifications';
import { IonApp, IonRouterOutlet, Platform } from '@ionic/angular/standalone';
import { environment } from 'src/environments/environment';
import { AppService } from './shared/service/app.service';
import { StatusBar, Style } from '@capacitor/status-bar';
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet],
})
export class AppComponent {
  constructor(
    private http: HttpClient,
    public _appService: AppService,
    private router: Router,
    public platform: Platform,
    private zone: NgZone
  ) {
    this.appInit();
    this.adaptiveUI();
  }

  appInit() {
    this.platform.ready().then(() => {
      if (Capacitor.isNativePlatform()) {
        StatusBar.setStyle({ style: Style.Light });
        StatusBar.setBackgroundColor({ color: '#FFFFFF' });
        NavigationBar.setColor({ color: '#f6f8fc' });

        PushNotifications.addListener(
          'pushNotificationReceived',
          (notification: PushNotificationSchema) => {
            // Show the notification payload if the app is open on the device.
            const data = JSON.parse(notification.data);

            if (data.project_public_id) {
              this.router.navigate([`/project/${data.project_public_id}/${data.message_public_id}`]);
            } else {
              this.router.navigate([`/message/${data.message_public_id}`]);
            }
            return false;
          }
        );

        PushNotifications.addListener(
          'pushNotificationActionPerformed',
          (notification: ActionPerformed) => {
            // Action to take when user tap on a notification.
            console.log(notification);
            if (notification.notification.data.action === 'OPEN_MESSAGE') {
              if (notification.notification.data.project_id !== null) {
                this.router.navigate([`/project/${notification.notification.data.message_public_id}/${notification.notification.data.message_public_id}`])
              } else {
                this.router.navigate([`/message/${notification.notification.data.message_public_id}`])
              }
            }

          }
        );

        // Deep link listener
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
          this.zone.run(() => {
            // Example url: https://hashback.app/tabs/tab2
            // slug = /tabs/tab2
            const slug = event.url.split('.app').pop();
            if (slug) {
              this.router.navigateByUrl(slug);
            }
            // If no match, do nothing - let regular routing
            // logic take over
          });
        });
      }
    });
    if (localStorage.getItem('token') && !localStorage.getItem('user')) {
      this.http.get(environment.hashBackApiUrl + '/user').subscribe((resultUser: any) => {
        if (resultUser.success === true) {
          this._appService.userData = resultUser.result;
          localStorage.setItem('user', JSON.stringify(this._appService.userData));
        } else {
          if (resultUser.result.error.name === 'TokenExpiredError') {
            localStorage.removeItem('token');
            this.router.navigate(['account/signin']);
          }
        }
      }, (error: any) => {
      });
    } else {
      const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') ?? '') : null;
      if (user) {
        this._appService.userData = user;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adaptiveUI();
  }

  adaptiveUI() {
    if (Capacitor.isNativePlatform()) {
      this._appService.isMobile = true;
    } else {
      if (this.platform.width() <= 768) {
        this._appService.isMobile = true;
      } else {
        this._appService.isMobile = false;
      }
    }
  }

  gotoFeedbacks() {
    const link = 'https://web.hashback.app/hashback';
    window.open(
      link,
      '_blank'
    );
  }

}
