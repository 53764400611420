import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/message',
    pathMatch: 'full'
  },
  {
    path: '',
    loadComponent: () => import('./pages/home/home.page').then(m => m.HomePage),
    canActivate: [AuthGuard],
    children: [
      {
        path: 'message',
        loadComponent: () => import('./pages/message/message-list/message-list.page').then(m => m.MessageListPage),
        canActivate: [AuthGuard]
      },
      {
        path: 'message/:publicId',
        loadComponent: () => import('./pages/message/message-list/message-list.page').then(m => m.MessageListPage),
        canActivate: [AuthGuard]
      },
      {
        path: 'account',
        loadComponent: () => import('./pages/account/account-view/account-view.page').then(m => m.AccountViewPage),
        canActivate: [AuthGuard]
      },
      {
        path: 'project',
        loadComponent: () => import('./pages/project/project-list/project-list.page').then(m => m.ProjectListPage),
        canActivate: [AuthGuard]
      },
      {
        path: 'project/new',
        loadComponent: () => import('./pages/project/project-create/project-create.page').then(m => m.ProjectCreatePage),
        canActivate: [AuthGuard]
      },
      {
        path: 'project/create',
        loadComponent: () => import('./pages/project/project-create/project-create.page').then(m => m.ProjectCreatePage)
      },
      {
        path: 'project/:publicId',
        loadComponent: () => import('./pages/project/project-view/project-view.page').then(m => m.ProjectViewPage)
      },
      {
        path: 'project/:publicId/:msgPublicId',
        loadComponent: () => import('./pages/project/project-view/project-view.page').then(m => m.ProjectViewPage)
      },
    ]
  },
  {
    path: 'account/signin',
    loadComponent: () => import('./pages/account/signin/signin.page').then(m => m.SigninPage)
  },
  {
    path: 'm/:publicId',
    loadComponent: () => import('./pages/message/message-view/message-view.page').then(m => m.MessageViewPage)
  },
  {
    path: 'send',
    loadComponent: () => import('./pages/message/message-send/message-send.page').then(m => m.MessageSendPage)
  },
  {
    path: 'send/:email',
    loadComponent: () => import('./pages/message/message-send/message-send.page').then(m => m.MessageSendPage)
  },
  {
    path: 'public/home',
    loadComponent: () => import('./pages/public/home/home.page').then(m => m.HomePage)
  },
  {
    path: 'public/app',
    loadComponent: () => import('./pages/public/app/app.page').then(m => m.AppPage)
  },
  {
    path: ':projectName/:publicId',
    loadComponent: () => import('./pages/message/message-view/message-view.page').then(m => m.MessageViewPage)
  },
  {
    path: ':projectName',
    loadComponent: () => import('./pages/message/message-send/message-send.page').then(m => m.MessageSendPage)
  },
  {
    path: '**',
    redirectTo: '/message',
  },
];
