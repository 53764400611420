export const environment = {
  firebase: {
    projectId: 'hashback-7f0aa',
    appId: '1:1049437872524:web:53e3ce68275b5a18de36a9',
    storageBucket: 'hashback-7f0aa.appspot.com',
    apiKey: 'AIzaSyBbFskAPu28MODeRCuAuJ4g8y1jdmb2Jio',
    authDomain: 'hashback-7f0aa.firebaseapp.com',
    messagingSenderId: '1049437872524',
    measurementId: 'G-0WS180NJSS',
    vapidKey: 'BAYWQ8P4qUnJAmQ-CHYnZ9KG2KN3QxGrpYGg53qvlnOl5N8J4s757Fv_PglDwO0jiGM4IaHGd6GlseuH8F1fWV4'
  },
  production: true,
  hashBackApiUrl: 'https://api.hashback.app',
  hashBackMsgUrl: 'https://m.hashback.app',
  enableSentry: true
};
